import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import Header from '../components/header'

interface Props {
  pageContext: { smallHeader?: boolean }
}

const Layout: React.FunctionComponent<Props> = ({ children, pageContext }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          small={pageContext.smallHeader}
        />
        <div
          style={{
            margin: '0 auto',
            maxWidth: '40rem',
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div>
      </>
    )}
  />
)

export default Layout
