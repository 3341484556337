import { Link } from 'gatsby'
import * as React from 'react'

interface HeaderProps {
  siteTitle?: string
  small?: boolean
}

const Header: React.FunctionComponent<HeaderProps> = ({
  siteTitle,
  small,
}) => {
  const smallStyles = {
    margin: '0 auto 3rem',
    padding: '0.5rem 1.0875rem',
    textAlign: 'left' as const,
  }
  const bigStyles = {
    margin: '0 auto 3rem',
    maxWidth: '40rem',
    padding: '10vh 1.0875rem',
    textAlign: 'center' as const,
  }
  return (
    <div
      style={{
        background: '#ff5721',
        marginBottom: '1.45rem',
      }}
    >
      <div style={small ? smallStyles : bigStyles}>
        <h1 style={{ margin: 0, ...small ? {fontSize: '1rem'} : {} }}>
          <Link
            to="/"
            style={{
              color: 'white',
              textDecoration: 'none',
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
    </div>
  )
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
